import React, { useState } from "react"
import Avatar from "@mui/material/Avatar"
import Button from "@mui/material/Button"
import CssBaseline from "@mui/material/CssBaseline"
import Link from "@mui/material/Link"
import Grid from "@mui/material/Grid"
import Box from "@mui/material/Box"
import LockOutlinedIcon from "@mui/icons-material/LockOutlined"
import Typography from "@mui/material/Typography"
import Container from "@mui/material/Container"
import { createTheme, ThemeProvider } from "@mui/material/styles"

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {"Copyright © "}Mastertool {new Date().getFullYear()}
      {"."}
    </Typography>
  )
}

const theme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "#363D60"
    },
    secondary: {
      main: "#9A377C"
    }
  }
})

export default function SignIn() {
  const [activeButtons, setActiveButtons] = useState("login")

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center"
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Login
          </Typography>
          <Typography component="h2" variant="h2">
            Mastertool
          </Typography>
          <Typography component="p" variant="caption">
            {/* The all-around, battle-tested tool for managing big Travian alliances. */}
            Roster building | Trooptool | Statistics | Def planner | Discord integration
          </Typography>

          {activeButtons === "login" ? (
            <>
              <Button
                type="button"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                onClick={() => {
                  window.location.href = "/login"
                }}
              >
                Login with Discord
              </Button>
              {/* <Typography component="h6" variant="h6" style={{ marginTop: "30px" }}>
                Login with Discord
              </Typography> */}
              <Typography
                component="p"
                variant="p"
                style={{ marginTop: "20px", marginBottom: "20px", textAlign: "left" }}
              >
                You can also login by typing the /login command on a Discord server with a
                Mastertool bot.
              </Typography>
              <Grid container>
                <Grid item xs>
                  <Link
                    variant="body2"
                    color="primary.contrastText"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setActiveButtons("signup")
                    }}
                  >
                    {"Don't have Mastertool yet? Sign Up"}
                  </Link>
                </Grid>
              </Grid>
            </>
          ) : (
            <>
              <Typography
                component="p"
                variant="p"
                style={{ marginTop: "20px", textAlign: "left" }}
              >
                Click below to add the Mastertool bot to your Discord server. This will
                register the app and set up role-based access control. You will need to
                have at least one role with the Manage Server permission to sign up;
                additional roles are defined in the app settings.
              </Typography>
              <Button
                type="button"
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                onClick={() => {
                  window.location.href = "/onboarding"
                }}
              >
                Sign up with Discord
              </Button>
              <Grid container>
                <Grid item xs>
                  <Link
                    variant="body2"
                    color="primary.contrastText"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setActiveButtons("login")
                    }}
                  >
                    {"Login instead"}
                  </Link>
                </Grid>
              </Grid>
            </>
          )}
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
    </ThemeProvider>
  )
}
